import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { constants } from 'appConstants';

export const AccountInformationOptions = [
  {
    name: 'Edit Name',
    display: 'Edit Name',
    value: 'Edit Name',
    constantValue: 'EDIT_NAME',
  },
  {
    name: 'Edit Email',
    display: 'Edit Email',
    value: 'Edit Email',
    constantValue: 'EDIT_EMAIL',
  },
  {
    name: 'Add Phone Number',
    display: 'Edit Phone Number',
    value: 'Add Phone Number',
    constantValue: 'ADD_PHONE_NUMBER',
  },
  {
    name: 'Change Password',
    display: 'Change Password',
    value: 'Change Password',
    constantValue: 'CHANGE_PASSWORD',
  },
  {
    name: 'Edit Payment Info',
    display: 'Edit Payment Info',
    value: 'Edit Payment Info',
    constantValue: 'EDIT_PAYMENT_INFO',
  },
];

export const editOptionClicked = (
  editing: string | null,
  openModal: (open: boolean) => void,
  setEditing: Dispatch<SetStateAction<string | null>>,
  customOptions?: {
    name: string;
    display: string;
    value: string;
    constantValue: string;
  }[]
) => {
  const options = customOptions || AccountInformationOptions;
  if (editing === 'Edit Payment Info') {
    openModal(true);
  } else {
    setEditing(
      options.find((option) => {
        return option.name === editing;
      })?.constantValue || editing
    );
  }
};

// Google-enabled brands: BeenVerified, Bumper, MoneyBot5000, NeighborWho, Number Guru, Ownerly, PeopleLooker, PeopleSmart, ReversePhone, Wingmate LC 04/07/25
// Apple-enabled brands: BeenVerified, Bumper, NeighborWho, Number Guru, PeopleLooker, Wingmate LC 04/07/25
const sso_enabled = {
  "google" : true,
  "apple" : false,
}

export const GenericCtaCardProps = (navigate: NavigateFunction) => {


  const props = [{
    headerText: 'Connect or Edit Login Options',
    bodyText: `Add your ${
      sso_enabled.google && sso_enabled.apple
        ? 'Google or Apple'
        : sso_enabled.google
        ? 'Google'
        : sso_enabled.apple
        ? 'Apple'
        : ''
    } Login on top of your account password & email`,
    buttonText: 'Edit Login Options',
    imgUrl: constants.images.imgSingleSignOnCta,
    onCtaClick: () => {
      window.open('https://www.peoplesmart.com/sso_connections', '_blank');
    },
  }];
  
  return { ctaCardProps: props, length: props.length };
};
